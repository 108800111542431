<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('status')">
                            <b-form-select v-model="updateData.status"
                                           :options="statusOptions"
                                           :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <div v-show="updateData.status == 'declined'">
                        <ValidationProvider name="explanation" rules="required_if:status,declined" v-slot="{errors}">
                            <b-form-group :label="$t('explanation')">
                                <b-form-textarea v-model="updateData.explanation"
                                                 :state="errors[0] ? false : null"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>
                <b-col cols="12" v-show="updateData.status === 'approved'">
                    <ValidationProvider name="class" :rules="`${updateData.status === 'approved' ? 'required' : ''}`" v-slot="{errors}">
                        <b-form-group :label="$t('class')">
                            <parameter-selectbox v-model="updateData.class"
                                code="classes"
                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center">
                    
                    <b-button variant="primary" class="mt-0" @click="update">
                        {{ $t('save') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import UndergraduateTransferService from '@/services/UndergraduateTransferService'
    import ParameterService from '@/services/ParameterService'

    import qs from "qs"

    export default {
        name: "AcademicUnitOfficeUpdateForm",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number,
                default: null
            },
            formData: {
                type: Object,
                default: null
            },
            
        },
        data() {
            return {
                updateData: {
                    status: null,
                    explanation: null,
                    class: null,
                },
                statusOptions: [
                    {value: null, text: '-'},
                    {value: 'approved', text: this.$t('approved')},
                    {value: 'declined', text: this.$t('declined')}
                ],
                prepSchoolOptions: [
                    {value: null, text: this.$t('select')},
                    {value: 1, text: this.$t('yes')},
                    {value: 0, text: this.$t('no')}
                ]
            }
        },
        methods: {
            async update() {
                if(this.checkPermission('undergraduatetransfer_approveforacademicunitoffice')) {
                    const valid =  await this.$refs.formModalValidate.validate();
                    if(!valid) {
                        return
                    }
                    

                    this.updateData.undergraduate_transfer_preference_id = this.formId
                    if (this.updateData.status == 'approved') {
                        delete this.updateData.explanation;
                    }else {
                        delete this.updateData.class;  
                    }

                    UndergraduateTransferService.academicUnitOfficeApprove(this.updateData)
                        .then(response => {
                            this.$emit('updated', {id: this.formId, sendStatus: this.updateData.status, classNo: this.updateData.class, success: response.data.success});
                            this.updateData = {
                                status: null,
                                explanation: null,
                                class: null,
                            }
                        })
                        .catch((e) => {
                            this.showErrors(e)
                        })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },
        },
    }
</script>
